<script setup lang="ts">
/* eslint-disable max-len */
export interface IconClose {
  fill?: string;
  width?: number;
  height?: number;
  isDarkTheme?: boolean;
}
const props = withDefaults(defineProps<IconClose>(), {
  fill: "#A9B7D1",
  width: 20,
  height: 20,
  isDarkTheme: true,
});
</script>

<template>
  <template v-if="props.isDarkTheme">
    <svg :width="props.width" :height="props.height" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <circle fill="#2C374B" cx="10" cy="10" r="10" />
        <path
          d="M6.171 13.829a.519.519 0 0 0 .4.171.52.52 0 0 0 .4-.171L10 10.8l3.028 3.029a.617.617 0 0 0 .4.171.618.618 0 0 0 .4-.171.552.552 0 0 0 0-.8L10.8 10l3.029-3.029a.552.552 0 0 0 0-.8.552.552 0 0 0-.8 0L10 9.2 6.971 6.171a.552.552 0 0 0-.8 0 .552.552 0 0 0 0 .8L9.2 10l-3.029 3.029a.552.552 0 0 0 0 .8z"
          :fill="props.fill"
        />
      </g>
    </svg>
  </template>
  <template v-else>
    <svg :width="props.width" :height="props.height" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <circle fill="#AFB6C1" cx="10" cy="10" r="10" />
        <path
          d="M6.171 13.829a.519.519 0 0 0 .4.171.52.52 0 0 0 .4-.171L10 10.8l3.028 3.029a.617.617 0 0 0 .4.171.618.618 0 0 0 .4-.171.552.552 0 0 0 0-.8L10.8 10l3.029-3.029a.552.552 0 0 0 0-.8.552.552 0 0 0-.8 0L10 9.2 6.971 6.171a.552.552 0 0 0-.8 0 .552.552 0 0 0 0 .8L9.2 10l-3.029 3.029a.552.552 0 0 0 0 .8z"
          fill="#FFF"
        />
      </g>
    </svg>
  </template>
</template>
